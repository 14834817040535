// import MyImage from '@shared/components/myImage'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import style from './style.module.scss'
// import closeIcon from '@assets/images/icon/close-icon.svg'

function StickyAds() {
  const router = useRouter()
  const [isClosed, setClosed] = useState(false)

  // function handleClose() {
  //   setClosed(true)
  //   document.body.classList.add('stickyAdRemoved')
  // }

  useEffect(() => {
    let ads
    const googletag = window.googletag || { cmd: [] }

    const shoAd = () => {
      googletag.cmd.push(() => {
        const width = window.innerWidth
        if (width >= 728) {
          document.getElementById('div-gpt-ad-1728476763530-0').style.display = 'none'
        } else {
          ads = googletag.defineSlot('/23202123844/CK_Desktop', [[320, 50]], 'div-gpt-ad-1728476763530-0').addService(googletag.pubads())
          document.getElementById('div-gpt-ad-1728476763530-0').style.minHeight = 50 + 'px'
          googletag.enableServices()
          googletag.display('div-gpt-ad-1728476763530-0')
        }
      })
    }

    const handleRouteChange = url => {
      setClosed(false)
      document.body.classList.remove('stickyAdRemoved')
      shoAd()
    }

    shoAd()
    router?.events?.on('routeChangeComplete', handleRouteChange)
    return () => {
      router?.events?.off('routeChangeComplete', handleRouteChange)
      ads && googletag?.destroySlots([ads])
      // ads && googletag?.destroySlots()
    }
  }, [router?.events])

  if (isClosed) return null
  if (router.asPath.includes('/mobile')) return null
  return (
    <div className={`${style?.sticky} d-md-none text-center c-transition start-0 end-0 position-fixed`}>
      {/* <button className={`${style.closebtn} position-absolute border-0 mt-n2 me-3 end-0 br-xs`} onClick={handleClose}>
        <MyImage src={closeIcon} alt="Close" width="12" height="12" />
      </button> */}
      <div id="div-gpt-ad-1728476763530-0" />
    </div>
  )
}
export default StickyAds
